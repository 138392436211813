import countries from './countries-i18n.nl';
import type { FileType } from "~/typings/types";

const filetypes: Record<FileType, string> = {
    audio: 'Geluidsbestand',
    file: 'Bestand',
    image: 'Afbeelding',
    pdf: 'PDF',
    video: 'Videobestand',
    text: 'Tekstbestand',
    document: 'Document',
    unknown: 'Onbekend'
}

export default {
    'Admin': 'Admin', // RoleName
    'Driver': 'Chauffeur', // RoleName
    'Planner': 'Planner', // RoleName
    'Nurse': 'Verpleegkundige', // RoleName
    'ExpenseStatus': {
        'Payed': 'Betaald',
        'Processed': 'Verwerkt',
        'Approved': 'Goedgekeurd',
        'Received': 'Ontvangen',
        'Rejected': 'Afgekeurd',
        'Concept': 'Concept',
    },
    'ExpenseType': {
        'WishCosts': 'Wenskosten',
        'TravelAllowance': 'Reiskosten',
        'Declaration': 'Declaratie',
        'Accommodation': 'Verblijfskosten',
        'Fuel': 'Brandstof',
    },
    'MileageReference': {
        'Maintenance': 'Onderhoud',
        'Event': 'Evenement'
    },
    'JournalType': {
        'PurchaseInvoices': 'Inkoopfacturen',
        'CardTransactions': 'Pinbetalingen',
    },
    'Gender': {
        'Female': 'Vrouw',
        'Male': 'Man',
        'Other': 'Anders',
        'Unknown': 'Onbekend',
    },
    'Saluation': {
        'Female': 'Mevr.',
        'Male': 'Dhr.',
        'Other': '',
        'Unknown': '',
    },
    'Mobility': {
        'Bedridden': 'Bedlegerig',
        'Walk': 'Loopt nog iets',
        'WalkWithHelp': 'Loopt met hulp',
        'WheelChair': 'Zit in rolstoel',
        'Other': 'Anders',
    },
    'ReservedProcedures': {
        'Oxygen': 'Zuurstof',
        'PeripheralInfusion': 'Perifeer infuus',
        'Drain': 'Drain',
        'TransurethralCatheter': 'Katheter (CAD)',
        'AsciticDrain': 'Ascites drain',
        'Stoma': 'Stoma',
        'StomachSiphon': 'Maaghevel',
        'PeripherallyInsertedCentralCatheter': 'PICC lijn',
        'CentralVenousCatheter': 'Centrale lijn',
        'IntravenousAntibiotic': 'Antibiotica intraveneus',
        'MorphineSubcutaneous': 'Morfine subcutaan',
        'MorphinePeripheral': 'Morfine perifeer',
        'DrainFlushing': 'Drain spoelen',
        'VacuumAssistedClosure': 'Vacuum pomp (wond)',
        'Nephrostomy': 'Nefrodrain',
        'ChestDrain': 'Thoraxdrain',
        'SuprapubicCatheter': 'Supra pubis katheter',
        'PercutaneousTranshepaticCholangiography': 'PTC drain',
        'IntratrachealSuctioning': 'Uitzuigen',
        'CardiacCare': 'Hartbewaking',
        'Monitoring': 'Monitorbewaking',
        'ArtificialRespiration': 'Beademing',
        'IntramuscularInjection': 'Intramusculaire injectie',
    },
    'Status': {
        'Active': 'Actief',
        'Completed': 'Afgerond',
        'Deleted': 'Verwijderd',
        'Executed': 'Uitgevoerd',
        'Inactive': 'Inactief',
        'Incomplete': 'Incompleet',
        'InProcess': 'In behandeling',
        'Pending': 'In afwachting',
        'Rejected': 'Vervallen',
        'Requested': 'Aangevraagd',
        'Scheduled': 'Ingepland',
    },
    'ContactType': {
        'Doctor': 'Behandelend arts',
        'ContactPerson': 'Contactpersoon',
        'Patient': 'Patiënt',
    },
    ...filetypes,
    ...countries,
}
