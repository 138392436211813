export default {
    Patient: 'Patiënt',
    'Patient name': 'Naam patiënt',
    Name: 'Naam',
    'Name user': 'Naam gebruiker',
    'Wish date': 'Wens datum',
    'Patient description': 'Vul hier de gegevens in van de patient en het adres waar de patient opgehaald dient te worden.',
    'Attending physician': 'Behandelend arts',
    'Enter the details of the attending physician here.': 'Vul hier de gegevens van de behandelend arts in.',
    'Connect': 'Koppel',
    'Disconnect': 'Ontkoppel',
    Contactpersons: 'Contactpersonen',
    Contactperson: 'Contactpersoon',
    'Contactperson description': 'Vul hier de gegevens van de contactpersoon in.',
    'Other information': 'Overige informatie',
    'Relationship to patient': 'Relatie tot patient',
    'First name': 'Voornaam',
    Insertion: 'Tussenvoegsel',
    'Last name prefix': 'Tussenvoegsel',
    'Last name': 'Achternaam',
    Street: 'Straat',
    'Street number': 'Huisnummer',
    'Street number addition': 'Toevoeging',
    Zipcode: 'Postcode',
    City: 'Stad',
    'Date of birth': 'Geboortedatum',
    Email: 'E-mail',
    'Living situation': 'Woonsituatie',
    true: 'Ja',
    false: 'Nee',
    'Phone number': 'Telefoonnummer',
    Previous: 'Vorige',
    Next: 'Volgende',
    Send: 'Versturen',
    'Status label': 'Status',
    'Pickup location': 'Ophaallocatie',
    'Received at': 'Ontvangen op',
    Volunteer: 'Vrijwilliger',
    Actions: 'Acties',
    Search: 'Zoeken',
    'Reset filters': 'Reset filters',
    User: 'Gebruiker',
    Role: 'Rol',
    Roles: 'Rollen',
    'Select a role': 'Selecteer een rol',
    'Select a volunteer': 'Selecteer een vrijwilliger',
    'Active since': 'Actief sinds',
    'Show inactive': 'Inactief tonen',
    'Starting location': 'Startlocatie',
    'No role': 'Geen rol',
    'Add wish': 'Wens toevoegen',
    Wishes: 'Wensen',
    Users: 'Gebruikers',
    Active: 'Actief',
    Inactive: 'Inactief',
    'One moment please': 'Een moment alsjeblieft',
    'We need a moment to fetch some data from the server': 'We hebben even nodig om wat data van de server op te halen.',
    'Page': 'Pagina',
    'not found': 'niet gevonden',
    'Sorry, the page you are looking for does not exist, or has been moved.': 'Sorry, de pagina die je probeert te bereiken bestaat niet, of niet meer.',
    'None': 'Geen',
    'access': 'toegang',
    'Sorry, you have insufficient rights to access this page.': 'Sorry, je hebt onvoldoende rechten om deze pagina te bekijken.',
    'Invite user': 'Gebruiker uitnodigen',
    'Description': 'Omschrijving',
    'All users': 'Alle gebruikers',
    'Active users': 'Actieve gebruikers',
    'Inactive users': 'Inactieve gebruikers',
    'Deleted users': 'Verwijderde gebruikers',
    'Incomplete users': 'Incomplete gebruikers',
    'Completed users': 'Complete gebruikers',
    'Send activation mail': 'Verzend activatie mail',
    'Till': 't/m',
    'Files': 'Bestanden',
    'Image': 'Afbeelding',
    'Video': 'Video',
    'Audio': 'Audio',
    'Document': 'Document',
    'File': 'Document',
    'Chose a mimetype': 'Kies een Bestandstype',
    'Add': 'Toevoegen',
    'Mimetype': 'Bestandstype',
    'Upload': 'Uploaden',
    'Upload date': 'Uploaddatum',
    'This file cannot be displayed.': 'Dit bestand kan niet worden weergegeven.',
    'File name': 'Bestandsnaam',
    'File size': 'Bestandsgrootte',
    'note': 'opmerking',
    'Yes': 'Ja',
    'No': 'Nee',
    Update: 'Wijzigen',
    Country: 'Land',
    Close: 'Sluiten',
    Location: 'Locatie',
    'Select a vehicle': 'Selecteer een voertuig',
    vehicle: 'voertuig',
    'Bank pincode': "Pincode bankpas",
    'Fuel pincode': "Pincode tankpas",
    'Additional route information': 'Aanvullende beschrijving route',
    'Departure at': 'Vertrek Ambulance Wens',
    'Arrival at': 'Aankomst op ophaallocatie',
    'Availability volunteers': 'Beschikbaarheid vrijwilligers',
    'There are {wishCount} wishes planned for this vehicle': 'Er zijn {wishCount} wensen gepland voor dit voertuig.',
    'There is 1 wish planned for this vehicle': 'Er is 1 wens gepland voor dit voertuig.',
    'If you remove the vehicle, it will also be disconnected from the active wish.': 'Indien je het voertuig verwijderd, zal deze ook bij de actieve wens worden ontkoppelt.',
    'If you remove the vehicle, it will also be disconnected from the active wishes.': 'Indien je het voertuig verwijderd, zal deze ook bij de actieve wensen worden ontkoppelt.',
    'User information': 'Gebruikersinformatie ',
    'The data for this user has been successfully saved.': 'De data voor deze gebruiker is succesvol opgeslagen.',
    'The address has been successfully updated.': 'Het adres is succesvol bijgewerkt.',
    'The address has been successfully deleted.': 'Het adres is succesvol verwijderd.',
    'There are currently no wishes scheduled.': 'Er staan op dit moment geen wensen ingepland.',
    'Download selection': 'Selectie downloaden',
    'Export SEPA': 'SEPA-bestand exporteren',
    'Amount': 'Bedrag',
    'Submitted by': 'Ingediend door',
    'Submitted at': 'Ingediend op',
    'Transaction at': 'Betaaldatum',
    'Payout to': 'Uitbetalen aan',
    'Submitted': 'Ingediend',
    'All expenses': 'Alle uitgaven',
    'Add expense': 'Uitgave toevoegen',
    'Add travel allowance': 'Reiskosten toevoegen',
    'Added expenses': 'Ingediende uitgaven',
    'Wish #': 'Wens #',
    'Wish status': 'Wens status',
    'Contains file': 'Bevat bestand',
    'Category': 'Soort',
    'Approve': 'Goedkeuren',
    'Edit expense': 'Declaratie wijzigen',
    'The wish is not modifiable because it is permanently saved and associated with a vehicle.': 'De wens is niet aanpasbaar, omdat deze definitief is opgeslagen en gekoppeld aan een voertuig.',
    'Disconnect vehicle': 'Voertuig ontkoppelen',
    'Mileage registration': 'Kilometerregistratie',
    'Start mileage': 'Beginstand KM',
    'Starting position': 'Beginstand',
    'Final position': 'Eindstand',
    'Entry date': 'Invoerdatum',
    'End mileage': 'Eindstand KM',
    'Distance KM': 'Aantal KM',
    'Connected to': 'Gekoppeld aan',
    'Add mileage': 'Registratie toevoegen',
    'Your mileage registration has been saved.': 'De kilometerregistratie is opgeslagen.',
    'No ambulance has yet been linked to this wish.': 'Er is nog geen ambulance aan deze wens gekoppeld.',
    'To the vehicle planning': 'Naar de voertuigplanning',
    'Add mileage registration': 'Kilometerregistratie toevoegen',
    'Edit mileage registration': 'Kilometerregistratie bewerken',
    'Start date': 'Datum vanaf',
    'End date': 'Datum tot',
    'Reference': 'Referentie',
    'Paid': 'Betaald'
}
