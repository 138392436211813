export default {
    'No open actions have been completed yet': 'Er zijn nog geen openstaande acties afgerond.',
    'This question type is not yet supported': 'Dit vraagtype wordt nog niet ondersteund.',
    'Cannot edit signature': 'Kan handtekening niet aanpassen',
    'The signature must first be removed to add a new one': 'De handtekening moet eerst verwijderd worden om een nieuwe toe te voegen.',
    'Complete answers': 'Antwoorden invullen',
    'Answer all mandatory questions': 'Beantwoord alle verplichte vragen.',
    'Answer at least one question': 'Beantwoord ten minste één vraag.',
    'Save checklist as draft': 'Tussentijds opslaan',
    'Save checklist as final': 'Opslaan',
    'multi-check': {},
}
